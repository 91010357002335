<template>
  <div>
    <div class="card card-custom card-stretch gutter-b farm-crops-conatiner">
      <!--begin::Header-->
      <div class="card-header border-0">
        <t-farmon-table-header></t-farmon-table-header>
        <div class="card-toolbar toolbar-symbol">
          <b-button
            variant="dark"
            class="rounded-sm"
            size="sm"
            @click="showPhotoModal()"
            >{{i18n.register}}</b-button
          >
        </div>
      </div>
      <!--begin::Body-->
      <div class="card-body pt-0">
        <div class="row">
            <div class="form-inline col-xxl-2 col-lg-3 mb-3">
              <label class="text font-size-h6 font-weight-bold mr-5"> {{i18n.startYmd}} </label>
              <b-form-datepicker
                  v-model="startYmd"
                  :locale="locale"
                  class="farm-custom-datepicker"
                  placeholder=""
                  style="width: 145px"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @input="changeDate()"
              ></b-form-datepicker>
            </div>
            <div class="form-inline col-xxl-2 col-lg-3 mb-3">
              <label class="text font-size-h6 font-weight-bold mr-5"> {{i18n.endYmd}} </label>
              <b-form-datepicker
                  v-model="endYmd"
                  :locale="locale"
                  class="farm-custom-datepicker"
                  placeholder=""
                  style="width: 145px"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @input="changeDate()"
              ></b-form-datepicker>
            </div>
            <b-list-group horizontal class="col-xxl-2 col-lg-2 mb-3 p-3 mr-auto">
              <b-list-group-item
                v-for="(dateRange) in dateRanges"
                button
                :key="dateRange.key"
                :active="activeDateRange === dateRange.key"
                @click="[onFixedDateRangeChanged(dateRange.key)]"
              >
                {{ dateRange.date }}
              </b-list-group-item>
            </b-list-group>
            <div class="col-xxl-2 col-lg-2 mb-3 p-3">
              <b-button class="btn btn-dark rounded float-right" size="sm" @click="gallery = !gallery">
                {{ gallery ? `${i18n.tableMode}` : `${i18n.galleryMode}` }}<span class="fas fa-reply ml-2"></span>
              </b-button>
            </div>
            <div v-show="gallery" class="col-xxl-12 col-lg-12 mt-5">
              <div v-for="(pItem, idx) in photoItems" :key="idx" class="row mb-12 p-3">
                <div v-if="(photoItems.findIndex(pi => pi.photoDate == pItem.photoDate) === idx)" class="col-xxl-12 p-3">
                  <label class="text font-size-h4 font-weight-bold mr-5"> {{ pItem.photoDate }} </label>
                </div>
                <div v-for="(photo, i) in pItem.imgJson" :key="i" class="col-xxl-2 mb-3 p-1 cursor-p">
                  <img class="w-100 rounded" :src="`${photo.filePath}`" @click="showPhotoModal(pItem)">
                </div>
              </div>
              <div v-if="photoItems.length === 0" class="mb-12 p-3">
                <div class="mb-3 pt-10 text-center">
                  {{ i18n.noData }}
                </div>
              </div>
            </div>
            <v-data-table
              :headers="fields"
              :items="photoItems"
              v-show="!gallery"
              class="elevation-1 farm-data-table col-xxl-12 col-lg-12"
              :no-data-text='i18n.noData'
              hide-default-footer
              :items-per-page="10"
            >
              <template v-slot:[`item.edit`]="{ item }">
                <b-button size="sm" variant="outline-dark" @click="showPhotoModal(item)">
                  <span class='icon-xs fas fa-pen mr-2'></span>
                  <span class="font-weight-bold">{{i18n.edit}}</span>
                </b-button>
                <b-button size="sm" variant="dark" class="rounded-sm" @click="deleteCropImgInfo(item)">
                  <span class="fas fa-trash font-size-sm mr-2"></span>
                  <span class="text font-weight-normal">{{i18n.delete}}</span>
                </b-button>
              </template>
            </v-data-table>
        </div>

        <!--begin::pagination-->
        <div v-if="totalPage" class="pagination mt-12">
          <b-pagination-nav
            :number-of-pages="totalPage"
            :link-gen="linkGen"
            use-router
            limit="10"
          >
            <template #first-text><i class="fas fa-angle-double-left"></i></template>
            <template #prev-text><i class="fas fa-angle-left"></i></template>
            <template #next-text><i class="fas fa-angle-right"></i></template>
            <template #last-text><i class="fas fa-angle-double-right"></i></template>
            <template #page="{ page, active }">
              <span v-if="active" class="font-size-sm font-weight-bold text-white">{{ page }}</span>
              <span v-else class="font-size-sm font-weight-bold text-dark">{{ page }}</span>
            </template>
          </b-pagination-nav>
        </div>
        <!--end::pagination-->

        <b-modal
          v-model="photoModal"
          size="xl"
          hide-footer
          hide-header
          no-close-on-backdrop
        >
        <div class="card card-custom card-stretch">
          <!--begin::Header-->
          <div class="card-header border-0 align-items-center">
            <h3 class="card-title align-items-start flex-column">
            <span class="title font-weight-bold">
              {{register == true ? i18n.registerPhoto : i18n.editPhoto}}
            </span>
            </h3>
            <div class="card-toolbar toolbar-symbol">
              <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="cancelPhotoModal()">
                <i class="la la-close font-size-h5"></i>
              </b-button>
            </div>
          </div>
          <!--end::Header-->
          <!-- begin::Body -->
          <div class="card-body pt-5 pb-5">
          <form class="form-horizontal">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ i18n.cropNm }}</label>
              <div class="col-sm-4">
                <b-form-select v-model="selectedCrop" :options="cropOptions"></b-form-select>
              </div>
              <label class="col-sm-2 col-form-label">{{ i18n.connectNm }}</label>
              <div class="col-sm-4">
                <b-form-select v-model="selectedConnect" :options="connectOptions"></b-form-select>
              </div>
            </div>
            <div class="form-group row">
              <label for="event-name" class="col-sm-2 col-form-label">{{ i18n.photoDate }}</label>
              <div class="col-sm-4">
                <b-form-datepicker
                  v-model="photoDate"
                  :locale="locale"
                  class="farm-custom-datepicker"
                  placeholder=""
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              ></b-form-datepicker>
              </div>
              <label class="col-sm-2 col-form-label">{{ i18n.sowingDate }}</label>
              <div class="col-sm-4">
                <b-form-datepicker
                  v-model="sowingDate"
                  :locale="locale"
                  class="farm-custom-datepicker"
                  placeholder=""
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              ></b-form-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ i18n.locationDetail }}</label>
              <div class="col-sm-10">
                <input v-model="locationDetail" type="text" class="farm-form-control form-control" :placeholder="`${i18n.locationPlaceholder}`">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ i18n.photos }}</label>
              <div class="col-sm-10 px-8">
                  <div class="row border border-1 rounded text-center align-items-center p-2">
                    <div v-for="(p,idx) in showPhotoUrl" :key="idx" class="col-sm-12 p-2" @mouseover="showButton = true" @mouseleave="showButton = false">
                      <img class="w-100 rounded" :src="`${p}`">
                      <button type="button" v-if="showButton && p.startsWith('http')" class="btn rounded-pill btn-info downBtn" @click="downloadFile(p)">
                        <b-icon icon="download" font-scale="2"></b-icon>
                      </button>
                      <button type="button" v-if="showButton" class="btn rounded-pill btn-danger deleteBtn" @click="deleteFile(idx)">
                        <b-icon icon="trash" font-scale="2"></b-icon>
                      </button>
                    </div>
                    <div class="col-sm-12 p-2">
                      <vue2-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true">
                        <div class="border-1 rounded border-secondary imagBox" style="border-style: dashed">
                          <i class='mt-14 mr-1 fas fa-upload'></i>Img Upload
                        </div>
                      </vue2-dropzone>
                    </div>
                  </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ i18n.desc }}</label>
              <div class="col-sm-10">
                <textarea
								v-model="photoDesc"
								:placeholder="`${i18n.descPlaceholder}`"
								rows="3"
								max-rows="10"
								wrap="soft"
								class="farm-form-control form-control" >
								</textarea>
              </div>
            </div>
          </form>
          </div>
          <!-- end::Body -->
          <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="primary" size="lg" @click="saveInfo()" :disabled="saveLoading">
              <span>{{register == true ? i18n.reg : i18n.edit}}</span>
              <span v-if="saveLoading" class="spinner farm-chart-spinner" style="position:absolute;"></span>
            </b-button>
          </div>
          <!--end::Footer-->
          </div>
        </b-modal>

    </div>
    <!--end::Body-->
    </div>
  </div>
</template>
<style>
</style>
<script>
import vue2Dropzone from 'vue2-dropzone';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ACT_GET_MY_FARM , ACT_DELETE_FILE, API_KEY, ACT_GET_CROP_IMAGE, ACT_INSERT_CROP_IMAGE, ACT_UPDATE_CROP_IMAGE , ACT_DELETE_CROP_IMAGE} from '@/core/services/variable';
import { ACT_ADD_ALERT, ACT_SHOW_ERROR_ALERT, ACT_SHOW_CONFIRM_ALERT } from '@/core/services/store/alert.module';
import { getItem, getItems, isSuccess, lengthCheck, utcToDate } from '@/core/services/funcs';
import TFarmonTableHeader from "@/components/TFarmonTableHeader";
import i18n from "@/core/plugins/vue-i18n.js";
import JwtService from '@/core/services/jwt.service';



export default {
  name: "FarmCalendar",
  components: {
    TFarmonTableHeader,
    vue2Dropzone
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: i18n.t("topNavBar.farm.info"), icon: "fas fa-clipboard" },
      { title: i18n.t("topNavBar.farm.farmPhotoRegistration") },
    ]);
    this.$store.dispatch(ACT_GET_MY_FARM).then(resp => {
      this.fno = getItem(resp).fno
      getItem(resp).farmConnects.forEach(c => {
        this.connectOptions.push({
          value: c.systemId,
          text: c.connectNm
        })
      })
      const crops = getItem(resp).farmCultivateCrops.filter((crop, idx) => getItem(resp).farmCultivateCrops.findIndex(c => c.cropNo === crop.cropNo) === idx)
      crops.forEach(c => {
        this.cropOptions.push({
          value: c.cropNo,
          text: c.cropNm
        })
      })
    })
    window.scrollTo(0, 0);
    if(Object.keys(i18n._localeChainCache)[0] == 'en'){
      this.locale = 'en'
    }
    this.changeFixedDate(182)
    this.getCropImginfo()
  },
  data() {
    return {
      i18n: {
        startYmd: i18n.t('farm.photoRegistration.startYmd'),
        endYmd: i18n.t('farm.photoRegistration.endYmd'),
        noData: i18n.t('farm.photoRegistration.noData'),
        tableMode: i18n.t('farm.photoRegistration.tableMode'),
        galleryMode: i18n.t('farm.photoRegistration.galleryMode'),
        photoDate: i18n.t('farm.photoRegistration.photoDate'),
        sowingDate: i18n.t('farm.photoRegistration.sowingDate'),
        connectNm: i18n.t('farm.photoRegistration.connectNm'),
        locationDetail: i18n.t('farm.photoRegistration.locationDetail'),
        photos: i18n.t('farm.photoRegistration.photos'),
        desc: i18n.t('farm.photoRegistration.desc'),
        locationPlaceholder: i18n.t('farm.photoRegistration.locationPlaceholder'),
        descPlaceholder: i18n.t('farm.photoRegistration.descPlaceholder'),
        reg: i18n.t('farm.photoRegistration.reg'),
        edit: i18n.t('farm.photoRegistration.edit'),
        cancel: i18n.t('farm.photoRegistration.cancel'),
        delete: i18n.t('farm.photoRegistration.delete'),
        registerPhoto: i18n.t('farm.photoRegistration.registerPhoto'),
        editPhoto: i18n.t('farm.photoRegistration.editPhoto'),
        register: i18n.t('farm.photoRegistration.register'),
        error: i18n.t('farm.photoRegistration.error'),
        and: i18n.t('farm.photoRegistration.and'),
        sheet: i18n.t('farm.photoRegistration.sheet'),
        invalidAll: i18n.t('farm.photoRegistration.invalidAll'),
        invalidPhotos: i18n.t('farm.photoRegistration.invalidPhotos'),
        cropImgAdded: i18n.t('farm.photoRegistration.cropImgAdded'),
        cropImgEditted: i18n.t('farm.photoRegistration.cropImgEditted'),
        cropImgdeleteAsk: i18n.t('farm.photoRegistration.cropImgdeleteAsk'),
        cropImgdeleted: i18n.t('farm.photoRegistration.cropImgdeleted'),
        cropNm: i18n.t('farm.photoRegistration.cropNm')
      },
      fno :0,
      startYmd: '',
      endYmd: '',
      locale: 'ko',
      activeDateRange: 'sixMonth',
      dateRanges: [
        { key: 'oneMonth', date: i18n.t('farm.photoRegistration.oneMonth') },
        { key: 'sixMonth', date: i18n.t('farm.photoRegistration.sixMonth') },
        { key: 'oneYear', date: i18n.t('farm.photoRegistration.oneYear') }
      ],
      gallery: true,
      fields: [
        { value: 'index', text: i18n.t('farm.photoRegistration.idx'), width: '80px' },
        { value: 'photoDate', text: i18n.t('farm.photoRegistration.photoDate') },
        { value: 'sowingDate', text: i18n.t('farm.photoRegistration.sowingDate') },
        { value: 'cropNm', text: i18n.t('farm.photoRegistration.cropNm') },
        { value: 'connectNm', text: i18n.t('farm.photoRegistration.connectNm') },
        { value: 'locationDetail', text: i18n.t('farm.photoRegistration.locationDetail') },
        { value: 'photos', text: i18n.t('farm.photoRegistration.photos') },
        { value: 'desc', text: i18n.t('farm.photoRegistration.desc') },
        { value: 'edit', text: '', width: '200px' },
      ],
      detailFields: [
        { value: 'photoDate', text: i18n.t('farm.photoRegistration.photoDate') },
        { value: 'sowingDate', text: i18n.t('farm.photoRegistration.sowingDate') },
        { value: 'connectNm', text: i18n.t('farm.photoRegistration.connectNm') },
        { value: 'locationDetail', text: i18n.t('farm.photoRegistration.locationDetail') },
        { value: 'photos', text: i18n.t('farm.photoRegistration.photos') },
        { value: 'desc', text: i18n.t('farm.photoRegistration.desc') },
      ],
      items: [],
      paging: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 10,
      },
      photoModal: false,
      register: false,
      photoDate: '',
      sowingDate: '',
      connectOptions: [],
      cropOptions: [],
      selectedCrop: 0,
      selectedConnect: 0,
      locationDetail: '',
      photoDesc: '',
      farmCropImgNo: 0,
      showButton: false,
      saveLoading: false,

      savePhotoFiles: [],
      showPhotoUrl: [],
      haveToRemoveFiles: [],
      haveToAddFiles: [],
      dropzoneOptions: {
        url: (this.$store.state.auth.apiUrl + '/web/v1/file'),
        resizeWidth: 800,
        headers: { 'Farm-Auth-Token': JwtService.getToken(), 'Api-Key': API_KEY },
        autoProcessQueue: false,
        parallelUploads: 10,
        clickable: true,
        acceptedFileTypes: 'image/*',
        uploadMultiple: false,
        paramName: "uploadFile", // The name that will be used to transfer the file
        maxFiles: 10,
        thumbnail: (file) => {
          this.showPhotoUrl.push(file.dataURL)
          this.haveToAddFiles.push(file)
        },
        success: (file, message) => {
          if(file && message && message.data){
            if(isSuccess(message.data)){
              if(lengthCheck(message.data)){
                const item = getItem(message.data)
                this.savePhotoFiles.push(item)
              }
            }else{
              this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, `${this.i18n.error}`)
            }
          }
        },
        // 새로운 사진 s3에 추가 완료
        queuecomplete: () => {
          this.$refs.myVueDropzone.dropzone.removeAllFiles()
          this.saveCropImgInfo()
          this.photoModal = false
        },
        error: () => {
          this.$refs.myVueDropzone.dropzone.removeAllFiles()
          this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, `${this.i18n.error}`)
        }
      },
    }
  },
  computed:{
    photoItems() {
      const items = []
      this.items.forEach((item, idx) => {
        let imgJson = []
        if(item.imgJson !== null) {
          imgJson = JSON.parse(item.imgJson)
        }
        items.push({
          'index': this.getCountIdx(idx),
          'photoDate' : item.photoDay,
          'sowingDate' : item.seedingDay,
          'cropNm': this.cropOptions.find(c => c.value === item.cropNo) != undefined ? this.cropOptions.find(c => c.value === item.cropNo).text : '',
          'connectNm' : this.connectOptions.find(c => c.value === item.systemId) != undefined ? this.connectOptions.find(c => c.value === item.systemId).text : '',
          'locationDetail' : item.rackDetailLocation,
          'photos' : imgJson.length === 0 ? '-' : imgJson[0].fileExtension + (imgJson.length > 1 ? ` ${this.i18n.and} ` + (imgJson.length - 1)  + `${this.i18n.sheet}` : ''),
          'desc' : item.description,
          'systemId': item.systemId,
          'cropNo': item.cropNo,
          'farmCropImgNo': item.farmCropImgNo,
          'imgJson' : imgJson
        })
      })
      return items
    },
    totalPage() {
      if(this.paging){
        return Math.ceil(this.paging.totalCount / this.paging.pageSize);
      }
      return 0;
    }
  },
  watch: {
    '$route'(to){
      this.paging.pageNo = (to.query.page || 1)
      this.getCropImginfo()
    },
    photoModal(val){
      if(val === false){
        this.saveLoading = false
      }
    }
  },
  methods: {
    cancelPhotoModal() {
      this.photoModal = false
      this.$refs.myVueDropzone.dropzone.removeAllFiles()
    },
    invalidateParams() {
      let message = ''
      let result = false
      if(!this.photoDate || !this.selectedConnect || !this.selectedCrop || !this.sowingDate || !this.locationDetail) {
        message = `${this.i18n.invalidAll}`
      }else if(this.haveToAddFiles.length + this.savePhotoFiles.length === 0) {
        message = `${this.i18n.invalidPhotos}`
      }else {
        result = true
      }
      if(!result) this.$store.dispatch(ACT_ADD_ALERT, {message: message, color: 'danger'});
      return result
    },
    changeDate() {
      this.getCropImginfo()
    },
    onFixedDateRangeChanged(dateRange) {
      if (dateRange === this.dateRanges[0].key) {
        this.changeFixedDate(30)
      } else if (dateRange === this.dateRanges[1].key) {
        this.changeFixedDate(182)
      } else if (dateRange === this.dateRanges[2].key) {
        this.changeFixedDate(365)
      }
      this.activeDateRange = dateRange
      this.getCropImginfo()
    },
    changeFixedDate(day) {
      let currentDate = new Date();
      const startDate = new Date(currentDate.setDate(currentDate.getDate() - day ));
      const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
      this.startYmd = startDateUTC.toISOString().slice(0, 10)
      this.endYmd = utcToDate(new Date(), 'yyyy-MM-dd')
    },
    getCountIdx(idx) {
      return this.paging.totalCount - ((this.paging.pageNo - 1) * this.paging.pageSize) - idx;
    },
    saveInfo() {
      if(this.invalidateParams()){
        this.saveLoading = true
        this.$refs.myVueDropzone.dropzone.processQueue()
        // 항목에서 사라진 파일 s3에서 삭제
        this.haveToRemoveFiles.forEach(f => {
          this.$store.dispatch(`${ACT_DELETE_FILE}`,f.fileUploadKey)
        })
        if(this.haveToAddFiles.length === 0){
          this.saveCropImgInfo()
          this.photoModal = false
        }
      }
    },
    saveCropImgInfo() {
      // 작물 데이터 입력, 수정
      if(this.register === true) {
        this.$store.dispatch(ACT_INSERT_CROP_IMAGE, { cropNo: this.selectedCrop, systemId: this.selectedConnect, rackDetailLocation: this.locationDetail, photoDay: this.photoDate, seedingDay: this.sowingDate, imgJson: JSON.stringify(this.savePhotoFiles), description: this.photoDesc }).then(() => {
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.cropImgAdded, color: 'success'})
          this.getCropImginfo()
        })
      } else {
        this.$store.dispatch(ACT_UPDATE_CROP_IMAGE, { farmCropImgNo: this.farmCropImgNo, cropNo: this.selectedCrop, systemId: this.selectedConnect, rackDetailLocation: this.locationDetail, photoDay: this.photoDate, seedingDay: this.sowingDate, imgJson: JSON.stringify(this.savePhotoFiles), description: this.photoDesc }).then(() => {
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.cropImgEditted, color: 'success'})
          this.getCropImginfo()
        })
      }
    },
    getCropImginfo() {
      this.$store.dispatch(ACT_GET_CROP_IMAGE, { startYmd: this.startYmd, endYmd: this.endYmd, pageNo: this.paging.pageNo, pageSize: this.paging.pageSize }).then(resp => {
        this.items = getItems(resp)
        this.paging.totalCount = resp.totalCount
      })
    },
    downloadFile(p) {
      window.open(p)
    },
    deleteFile(idx) {
      if(this.showPhotoUrl[idx].slice(0, 5) !== 'https'){
        const file = this.haveToAddFiles.find(f => f.dataURL === this.showPhotoUrl[idx])
        this.$refs.myVueDropzone.dropzone.removeFile(file)
      } else {
        const fIdx = this.savePhotoFiles.findIndex(f => f.filePath === this.showPhotoUrl[idx])
        this.haveToRemoveFiles.push(this.savePhotoFiles[fIdx])
        this.savePhotoFiles.splice(fIdx, 1)
      }
      this.showPhotoUrl.splice(idx,1)
    },
    showPhotoModal(item) {
      this.photoModal = true
      if (item === undefined) {
        this.register = true
        this.savePhotoFiles = []
        this.showPhotoUrl = []
        this.haveToRemoveFiles = []
        this.haveToAddFiles = []
        let currentDate = new Date();
        const startDate = new Date(currentDate.setDate(currentDate.getDate() - 30 ))
        const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()))
        this.sowingDate = startDateUTC.toISOString().slice(0, 10)
        this.photoDate = new Date().toISOString().slice(0, 10)
        this.selectedConnect = this.connectOptions[0].value
        this.selectedCrop = this.cropOptions[0].value
        this.locationDetail = ''
        this.photoDesc = ''
      } else {
        this.farmCropImgNo = item.farmCropImgNo
        this.register = false
        this.savePhotoFiles = JSON.parse(JSON.stringify(item.imgJson))
        this.showPhotoUrl = JSON.parse(JSON.stringify(item.imgJson.map(item => item.filePath)))
        this.haveToRemoveFiles = []
        this.haveToAddFiles = []
        this.photoDate = item.photoDate
        this.sowingDate = item.sowingDate
        this.selectedConnect = item.systemId
        this.selectedCrop = item.cropNo
        this.locationDetail = item.locationDetail
        this.photoDesc = item.desc
      }
    },
    deleteCropImgInfo(item) {
      const yesCallback = () => {
        this.$store.dispatch(`${ACT_DELETE_CROP_IMAGE}`, item.farmCropImgNo).then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.cropImgdeleted, color: 'success'});
            item.imgJson.forEach(f => {
              this.$store.dispatch(`${ACT_DELETE_FILE}`,f.fileUploadKey)
            })
            this.getCropImginfo()
          }else{
            this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, getResult(resp).message)
          }
        }).catch(e => {
          console.error(e)
          this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, null)
        })

      }

      this.$store.dispatch(`${ACT_SHOW_CONFIRM_ALERT}`, {
        info: {title: this.i18n.cropImgdeleteAsk},
        callback: yesCallback
      })
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    }
  }

};
</script>

<style>
  .imagBox {
    height: 120px;
    cursor: pointer;
  }
  .cursor-p {
    cursor: pointer;
  }
  .downBtn {
    position: absolute;
    opacity: 0.5;
    bottom: 5%;
    left : 5%;
  }
  .downBtn:hover {
    position: absolute;
    opacity: 0.9;
    bottom: 5%;
    left : 5%;
  }
  .deleteBtn {
    position: absolute;
    opacity: 0.5;
    bottom: 5%;
    right : 5%;
  }
  .deleteBtn:hover {
    position: absolute;
    opacity: 0.9;
    bottom: 5%;
    right : 5%;
  }
  .dz-file-preview {
    display: none;
  }
</style>